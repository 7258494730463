@font-face {
  font-family: "StagSansApp-Book";
  src: url("StagSans-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "StagSansApp-Medium";
  src: url("StagSans-Medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "StagSansApp-Light";
  src: url("StagSans-Light.woff") format("woff");
  font-display: swap;
}
