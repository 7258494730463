.btn-primary-outline {
    background-color: $white;
    border: 2px solid $pn-base-color;
    color: $pn-base-color;
    margin-right: 5px;
    &:hover {
        border: 2px solid $white;
        background-color: $pn-base-color;
        color: $white;
    }
}

.btn-primary {
    background-color: $pn-base-color;
    color: $white;
    &:hover {
        color: $white;
        background-color: $pn-base-color;
    }
    &:disabled {
        color: $white;
        background-color: $pn-base-color;
        opacity: 0.4;
    }
}

.btn-secondary {
    background-color: $pn-disabled-button;
    color: $black;
    &:disabled {
        color: $black;
        background-color: $pn-disabled-button;
        opacity: 0.4;
        border: 0px !important;
    }
    &:hover {
        color: $black;
        background-color: $pn-disabled-button;
    }
}