$pn-base-color: #00a0d6;
$pn-highlight-color: #0c7bad;
$white: #ffffff;
$black: #000000;
$pn-disabled-button: #dedede;
$pn-sidemenu-background-color: rgba(30, 30, 30, 0.8);
$pn-grey:#ced4da;
$pn-darkgrey : #777474;
$pn-field-error: #e02d00;
$pn-field-success: #108b33;
$pn-field-warning: #E36200;
$pn-banner-background: #ffffe0;
$pn-banner-error-font: #E36200;


$pn-toast-error-background: #fae8e2;
$pn-toast-success-background: #e2f1e8;
$pn-toast-warning-background: #FCEFE5;

$theme-colors: (
    "pn-primary": $pn-base-color,
    "pn-secondary": $pn-highlight-color,
    "pn-danger": $pn-field-error
);

@mixin no-outline {
    outline: none !important;
    box-shadow: none !important;
}

@mixin flex-display($display, $align: "", $justify: "") {
    display: $display;

    @if $align !="" {
        align-items: $align;
    }

    @if $justify !="" {
        justify-content: $justify;
    }
}

@mixin panel-section() {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 4px #00000026;
    padding: 0rem 0.75rem;
    background-color: #ffffff;
}