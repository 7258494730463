/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import "./app/base-color/variables.scss";
@import "assets/fonts/font.css";
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "assets/styles/custom-primeng.scss";
@import "assets/styles/buttons.scss";
@import "~leaflet/dist/leaflet.css";

.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $pn-disabled-button;
    opacity: 1;
    /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.p-inputtext {
    width: 100% !important;
    font-family: StagSansApp-Book, sans-serif !important;

    &.p-placeholder,
    &::placeholder {
        color: $pn-disabled-button !important;
        opacity: 1 !important;
    }
}

.form-control {
    font-family: StagSansApp-Book, sans-serif !important;
    font-size: .875rem;
    line-height: 1.063rem;
    color: #1e1e1e;
    border: 1px solid $pn-grey;
    border-radius: 4px;
    padding: .5rem .75rem;
    &:focus {
        border-color: $pn-highlight-color!important;
        box-shadow: none !important;
    }
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*:hover::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 20px;
}

*:hover::-webkit-scrollbar-thumb {
    background: rgba(141, 136, 136, 0.5);
}

::selection {
    color: #ffffff;
    background: $pn-highlight-color;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: StagSansApp-Book, sans-serif !important;
    font-weight: normal;
    font-style: normal;
    line-height: 1.5;
    font-size: 16px;

    .ui-widget,
    .p-component {
        font-family: StagSansApp-Book, sans-serif !important;
        @include no-outline();
    }

    overflow: auto !important;
}

.as-split-gutter {
    background: transparent !important;

    .as-split-gutter-icon {
        background-image: none !important;
        border-radius: 4px;
        position: relative;
        background-color: #000;
    }
}

.as-vertical>.as-split-gutter>.as-split-gutter-icon {
    width: 28px !important;
    height: 9px !important;

    &:before {
        content: "";
        border-top: 1px solid #fff;
        width: 50%;
        top: 45%;
        left: 24%;
        position: absolute;
    }
}

as-split {
    box-shadow: 0px 0px 4px #00000026;
}

.as-split-area {
    box-shadow: 0px 0px 4px #00000026;
}

.as-horizontal>.as-split-gutter>.as-split-gutter-icon {
    width: 9px !important;
    height: 28px !important;

    &:before {
        content: "";
        border-left: 1px solid #fff;
        height: 50%;
        left: 45%;
        top: 24%;
        position: absolute;
    }
}

.app-name {
    color: $white;
    font-size: 1.25rem;
    line-height: 2.7rem;
}

p-table {
    .p-datatable {

        .p-datatable-loading-overlay {
            background: rgba(255, 255, 255, 0.8) !important;
            z-index: 10 !important;
        }

        .p-datatable-loading-icon {
            font-weight: bolder;
            color: $pn-base-color;
        }
    }
}

//*** Main kpi css ****//
.kpiMainKeyMatrix {
    display: flex;
    justify-content: space-between;
    margin: 8px 20px 8px;
    font-size: 14px;
    font-family: StagSansApp-Medium, sans-serif;
    .matrixCard {
      box-shadow: 0px 0px 4px #00000026;
      border-radius: 2px;
      padding: 8px 10px;
      background-color: #fff;
      flex: 1 1 0;
      width: 0;
      margin-right: 9px;
      &:last-child {
        margin-right: 0;
      }
      .keyMatrixLable {
        color: #007cb8;
        font-size: 14px;
      }
      .MatrixValuation {
        display: flex;
        align-items: center;
        min-height: 50px;
        .percentage {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          margin-right: 18px;
          border-radius: 50px;
        }
  
        .compleatedTask {
          background-color: #00b45a15;
          width: 50px;
        }
        .deliverableItems {
          background-color: #ffa4291f;
          width: 50px;
        }
        .taskPerHour,
        .totalTime {
          margin-right: 13px;
        }
        .actualValues {
          display: flex;
          .ValueLables {
            margin-right: 10px;
            font-size: 12px;
          }
          .matrixValues {
            font-size: 12px;
            font-family: StagSansMediumFont ,sans-serif !important;
            font-weight: 800;
          }
        }
      }
    }
  }
  //*** End Main kpi css ****//
  //common style for taskbank summary
  .taskBankSummary {
      font-size: 12px;
      letter-spacing: 0px;
      margin: 0 0 16px;
      align-items: center;
      display: flex;
      position: relative;
    //   bottom: 5px;
      div {
          padding: 0 25px;
      }
  }

  .pointer-cursor {
      cursor: pointer !important;
  }
  
  .no-record-label {
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .error-validation {
    font-size: 0.75rem;
    color: $pn-field-error;
    padding-top: 0.25rem;
    line-height: 0.75rem;
    display: block;
}
//create route
.form-label{
    font-family: StagSansMediumFont, sans-serif;
    font-size: 12px;
    display: block;
}
.button{
    margin:1px;
}


  .error-message {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: $pn-toast-error-background;
    color: $pn-field-error ;
   }

.anchor {
    color: $pn-base-color;
    text-decoration: underline;
    cursor: pointer;
}

.info-icon {
    font-size: 12px; color:$pn-field-warning;
  }


.edit-icon{
    border-color: transparent;
    background-color: transparent;
}